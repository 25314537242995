import './App.css';
import Routing from './routes/Routing';


const App =()=> {
  return (
      <Routing />
  );
}

export default App;
