import { Divider, Grid } from '@mui/material'
import React from 'react'
import Input from '../components/sharedComponents/input'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import Styles from "../styles/login.module.scss"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Profile from '../components/Profile';
// import SideBar from '../components/SideBar';
const HrDashboard = () => {


  return (
    <>
     <Profile/>
    
    </>
  )
}

export default HrDashboard

